
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'width': '32',
                'height': '32',
                'viewBox': '0 0 32 32',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg'
            }, _createElement('path', {
                'd': 'M14.0003 25.3333C20.2596 25.3333 25.3337 20.2592 25.3337 14C25.3337 7.74073 20.2596 2.66663 14.0003 2.66663C7.7411 2.66663 2.66699 7.74073 2.66699 14C2.66699 20.2592 7.7411 25.3333 14.0003 25.3333Z',
                'stroke': '#326493',
                'strokeWidth': '2',
                'strokeLinejoin': 'round'
            }), _createElement('path', {
                'd': 'M22.1481 22.1479L27.8049 27.8048',
                'stroke': '#326493',
                'strokeWidth': '2',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]