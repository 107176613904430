export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--secondary',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',

  PRODUCT_REPEATER_CLASS: 'productGrid',

  SEARCH_BOX_CLASS: 'container',
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'search-icon',
  SEARCH_BOX_PLACEHOLDER: 'Enter Year Model & Part (e.g. 1965 Mustang Headliner)',

  GARAGE_BUTTON_CLASS: 'navUser-action',
  GARAGE_SIZE: '<span class="garage-size countPill countPill--positive" key="garage-size">{{size}}</span>',

  DISCARD_VEHICLE: 'Clear',
  CHANGE_VEHICLE: 'Change',
};
