const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel', 'Number_of_Doors', 'Body_Style'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const searchResultsPageUrl = '/search-results/';
const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';
const isCategorySelectionPage = window.location.pathname === categorySelectionPageUrl;

const HideResults = () => {
  const { selection, query, sort } = window.Convermax.getSearchRequest();
  const isVehicleSelected = window.Convermax.isVehicleSelected();

  if (
    isVehicleSelected ||
    selection.length > 0 ||
    query.length > 0 ||
    sort !== 'relevance' ||
    window.document.body.classList.contains('cm_search') ||
    window.document.querySelector('.cmTemplate_timeout') ||
    window.document.querySelector('.cmTemplate_networkError')
  ) {
    window.document.body.classList.add('cm_hideDefaultResults');
  }
};

const searchResultsUpdateCallback = () => {
  HideResults();
};

export default {
  platform: 'bigcommerce',
  searchPageUrl: searchResultsPageUrl,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ||
      window.location.pathname === brandSelectionPageUrl
        ? 'parts'
        : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    expectResponseRedirect: true,
    isVehicleSelectionIsolated: true,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '.page .page-content',
      template: 'SearchPage',
      visibleIf: () => window.location.pathname === searchResultsPageUrl,
    },
    {
      name: 'CategorySearchHeader',
      type: 'SearchPage',
      location: '#cm_CategorySearchHeader',
      template: 'CategorySearchHeader',
      visibleIf: () =>
        ![brandSelectionPageUrl, searchResultsPageUrl, categorySelectionPageUrl].includes(
          window.location.pathname,
        ),
    },
    {
      name: 'CategorySearchResults',
      type: 'SearchPage',
      location: { firstChildOf: '#cm_CategorySearchResults' },
      template: 'CategorySearchResults',
      visibleIf: () =>
        ![brandSelectionPageUrl, searchResultsPageUrl, categorySelectionPageUrl].includes(
          window.location.pathname,
        ),
    },
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: '#cm_CategoryFacetPanel',
      template: 'CategoryFacetPanel',
      visibleIf: () =>
        ![brandSelectionPageUrl, searchResultsPageUrl, categorySelectionPageUrl].includes(
          window.location.pathname,
        ),
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
      updateCallback: searchResultsUpdateCallback,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
      initExpandedFacets: ['category', 'categories', 'brand_name', 'stock', 'Fitment'],
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBox',
      location: { replace: '.col.cas-banner-2.desktop-visible', class: 'col cas-banner-2 desktop-visible' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '#cm_SearchBoxDialogButton',
        class: 'cm_search-box-root__dialog-button_container',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'FilterSearchBox',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_HomeVehicleWidget',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 700,
      useSearchableDropdown: true,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
      useSearchableDropdown: true,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: extraFitmentFields,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      useSearchableDropdown: true,
    },
    {
      name: 'Garage',
      location: {
        replace: '#cm_Garage',
        wrapper: 'li',
        class: 'cm_garage__container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm_VerifyFitment',
      template: 'fitmentSearch/verifyFitment',
      useSearchableDropdown: true,
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView-description .tabs-contents',
        class: 'tab-content',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { lastChildOf: '.productView-description ul', wrapper: 'li', class: 'tab' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => isCategorySelectionPage,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
