import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/collectors-auto-supply/Templates/SearchPage.rt'
import TemplateCategorySearchHeader from 'Stores/collectors-auto-supply/Templates/CategorySearchHeader.rt'
import TemplateCategorySearchResults from 'Stores/collectors-auto-supply/Templates/CategorySearchResults.rt'
import TemplateCategoryFacetPanel from 'Stores/collectors-auto-supply/Templates/CategoryFacetPanel.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import { FacetBar } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { FilterSearchBox } from 'Components'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import TemplatefitmentSearchfitmentTableTab from 'Stores/collectors-auto-supply/Templates/fitmentSearch/fitmentTableTab.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialogVehicleSpecs from 'Stores/_default-store/Templates/FacetDialogVehicleSpecs.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
FacetPanel,
FacetBar,
SearchBox,
SearchBoxDialogButton,
FilterSearchBox,
VehicleWidget,
Garage,
FitmentTable,
FacetTiles,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'CategorySearchHeader': TemplateCategorySearchHeader,
'CategorySearchResults': TemplateCategorySearchResults,
'CategoryFacetPanel': TemplateCategoryFacetPanel,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'fitmentSearch/fitmentTableTab': TemplatefitmentSearchfitmentTableTab,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'FacetDialogVehicleSpecs': TemplateFacetDialogVehicleSpecs,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};